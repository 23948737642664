import React from "react"
import { Content, HeaderTitle } from "./styles"

export function PoliticaDePrivacidade() {
  return (
    <>
      <HeaderTitle>
        <h1>Política de Privacidade</h1>
      </HeaderTitle>
      <Content>
        <p>
          Nós, da Riversoft Integração e Inovação Ltda., estamos comprometidos
          em resguardar sua privacidade. O intuito deste documento é esclarecer
          quais informações são coletadas dos usuários de nosso site (
          <a
            href="https://www.riversoft.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.riversoft.com.br/
          </a>
          ) e de que forma esses dados são manipulados e utilizados.
        </p>
        <p>
          A aceitação da nossa Política será feita quando você se cadastrar em
          algum dos formulários do nosso site. Isso indicará que você está
          ciente e em total acordo com a forma como utilizaremos as suas
          informações e seus dados.
        </p>
        <p>
          Caso não concorde com esta Política, por favor, não continue o seu
          procedimento de registro. Todavia, nos informe a sua discordância para
          que possamos melhorá-los.
        </p>
        <p>
          Qualquer dúvida em relação à nossa política de privacidade pode ser
          esclarecida entrando em contato conosco através do e-mail{" "}
          <a href="mailto:info@riversoft.com.br">info@riversoft.com.br</a>.
        </p>

        <h2>1. Sobre a coleta de dados</h2>
        <p>
          Coletamos informações de identificação pessoal – como nome, telefone,
          e-mail, empresa em que trabalha e cargo – via preenchimento dos
          formulários para download de nossos conteúdos gratuitos, solicitações
          de proposta e inscrições em newsletter. Eventualmente, a solicitação
          de algumas informações pode ser feita por meio de contato direto da
          Riversoft com os usuários via e-mail ou telefone.
        </p>
        <p>
          Quando você visita nosso site, é inserido um ‘cookie’ no seu navegador
          por meio do software Google Analytics, para identificar quantas vezes
          você retorna ao nosso endereço. São coletadas informações como
          endereço IP, localização geográfica, fonte de referência, tipo de
          navegador, duração da visita e páginas visitadas.
        </p>
        <p>
          Armazenamos informações a respeito de todos os contatos já realizados
          com nossos usuários, como conteúdos baixados a partir de nossas
          páginas e interações via e-mail.
        </p>
        <p>
          Ao utilizar sua conta no Facebook para se integrar aos nossos
          formulários, poderemos coletar todos os dados que forem
          disponibilizados pelo Facebook ao nosso sistema.
        </p>
        <p>
          Também esclarecemos que a coleta de dados é feita respeitando o
          princípio da necessidade, que significa limitar o uso dos dados ao
          mínimo necessário para que se possa atingir a finalidade pretendida.
        </p>

        <h2>2. Sobre o uso de seus dados pessoais</h2>
        <p>
          Todos os dados e informações sobre você são tratados como
          confidenciais, e somente os usaremos para os fins aqui descritos e
          autorizados por você, visando sempre melhorar a sua experiência como
          usuário.
        </p>
        <p>
          Eventualmente, poderemos utilizar dados para finalidades não previstas
          nesta Política de Privacidade, mas estes estarão dentro das suas
          legítimas expectativas. O eventual uso dos seus dados para finalidades
          que não cumpram com essa prerrogativa serão feitos mediante sua
          autorização prévia.
        </p>
        <p>
          Seu e-mail é utilizado para a operação de envio do material ou
          informação por você requisitada no preenchimento do formulário. Também
          pode ser usado para envio de Newsletters. O e-mail será utilizado
          ainda para comunicar o lançamento de novos materiais gratuitos ou de
          novos produtos da Riversoft Integração e Inovação Ltda. No entanto, o
          usuário pode cancelar a assinatura a qualquer momento.
        </p>
        <p>
          Os dados de download poderão ser divulgados como pesquisas e
          estatísticas, não sendo reveladas abertamente nenhuma informação
          pessoal, a menos que autorizada explicitamente.
        </p>
        <p>
          Funcionários da Riversoft Integração e Inovação Ltda. poderão
          eventualmente entrar em contato via e-mail ou telefone para:
        </p>
        <ul>
          <li>Fazer pesquisas ou apresentar produtos e serviços;</li>
          <li>
            Enviar a você mensagens a respeito de suporte ou serviço, como
            alertas, notificações e atualizações;
          </li>
          <li>
            Nos comunicar com você sobre produtos, serviços, promoções,
            notícias, atualizações, eventos e outros assuntos que você possa ter
            interesse;
          </li>
          <li>
            Realizar publicidade direcionada conforme seus gostos, interesses e
            outras informações coletadas;
          </li>
          <li>
            Personalizar o serviço para este adequar cada vez mais aos seus
            gostos e interesses;
          </li>
          <li>
            Para qualquer fim que você autorizar no momento da coleta de dados.
          </li>
        </ul>

        <h2>3. Sobre o acesso aos seus dados pessoais</h2>
        <p>
          Poderão ver suas informações pessoais apenas funcionários da Riversoft
          Integração e Inovação Ltda., e dentre estes, somente as pessoas com as
          devidas autorizações. Eventualmente, caso a inserção de suas
          informações se dê em ações criadas em parcerias, os parceiros
          explicitamente identificados também terão acesso à informação. Nenhum
          dado pessoal poderá ser divulgado publicamente.
        </p>
        <p>
          Todos os seus dados são confidenciais e qualquer uso destes estará de
          acordo com a presente Política. A Riversoft Integração e Inovação
          Ltda. empreenderá todos os esforços razoáveis de mercado para garantir
          a segurança dos nossos sistemas e dos seus dados.
        </p>
        <p>
          Manteremos os dados e informações somente até quando estes forem
          necessários ou relevantes para as finalidades descritas nesta
          Política, ou em caso de períodos pré-determinados por lei, ou até
          quando estes forem necessários para a manutenção de interesses
          legítimos da Riversoft Integração e Inovação Ltda.
        </p>
        <p>
          Diante disso, após atingidos os interesses da empresa, imediatamente
          efetuaremos a adequada eliminação de tais informações.
        </p>

        <h2>4. Sobre o compartilhamento de dados</h2>
        <p>
          Ao clicar nos botões de compartilhamento de conteúdo nas redes sociais
          disponíveis em nossas páginas, o usuário estará publicando o conteúdo
          por meio de seu perfil na rede selecionada. A Riversoft Integração e
          Inovação Ltda. não tem acesso ao login e senha dos usuários nessas
          redes, nem publicará conteúdo em nome do usuário sem que ele realize
          esta ação. O mesmo vale para a obtenção de materiais educativos por
          meio da opção “Pagamento Social”.
        </p>
        <p>
          Todos os dados, informações e conteúdos sobre você podem ser
          considerados ativos no caso de negociações em que a Riversoft
          Integração e Inovação Ltda. fizer parte. Portanto, nos reservamos o
          direito de incluir seus dados dentre os ativos da empresa caso esta
          venha a ser vendida, adquirida ou fundida com outra. Por meio desta
          Política você concorda e está ciente desta possibilidade.
        </p>

        <p>
          A Riversoft Integração e Inovação Ltda. se reserva o direito de
          fornecer seus dados e informações sobre você, incluindo interações
          suas, caso seja requisitado judicialmente para tanto, ato necessário
          para que a empresa esteja em conformidade com as leis nacionais, ou
          caso você autorize expressamente.
        </p>

        <h2>
          5. Sobre o cancelamento da assinatura e alteração/exclusão de
          informações pessoais
        </h2>
        <p>
          Você pode optar por não receber mais qualquer tipo de e-mail da
          Riversoft Integração e Inovação Ltda. Em todos os e-mails que enviamos
          há sempre um link para cancelar a assinatura disponível nas últimas
          linhas. Ao clicar nesse link você será automaticamente descadastrado
          da lista.
        </p>
        <p>
          É importante mencionar que ao preencher qualquer formulário novamente
          ficará caracterizada a reinserção do seu e-mail à lista. Portanto, a
          requisição de cancelamento deve ser feita novamente caso seja de seu
          interesse.
        </p>

        <p>
          Todos os dados coletados serão excluídos de nossos servidores quando
          você assim requisitar ou quando estes não forem mais necessários ou
          relevantes para lhe oferecermos nossas comunicações, salvo se houver
          qualquer outra razão para a sua manutenção, como eventual obrigação
          legal de retenção de dados ou necessidade de preservação destes para
          resguardo de direitos da Riversoft Integração e Inovação Ltda. Para
          alterar suas informações pessoais ou excluí-las do nosso banco de
          dados, basta enviar um e-mail para{" "}
          <a href="mailto:info@riversoft.com.br">info@riversoft.com.br</a>.
        </p>

        <h2>6. Proteção dos dados coletados</h2>
        <p>
          A Riversoft Integração e Inovação Ltda. esclarece seu absoluto empenho
          na proteção efetiva de qualquer dado coletado. Desta forma, em
          cumprimento à Lei nº13.709/18, a Riversoft definirá um responsável que
          terá como principal atividade o monitoramento e disseminação das boas
          práticas em relação à proteção de dados pessoais perante funcionários
          e contratados no âmbito da empresa.
        </p>

        <h2>7. Do acesso às informações pessoais</h2>
        <p>
          Em respeito ao Direito de Acesso, a Riversoft Integração e Inovação
          Ltda. garante a possibilidade de obtenção, mediante requisição, junto
          aos controladores, de todos os dados pessoais que estão sendo
          tratados, e como consequência disso, os direitos de retificação e
          atualização, haja vista o nosso interesse de mantê-los sempre corretos
          e atualizados.
        </p>

        <h2>8. Mudanças na Política de Privacidade</h2>
        <p>
          Essa Política de Privacidade pode passar por atualizações. Desta
          forma, recomendamos visitar periodicamente esta página para que você
          tenha conhecimento sobre as modificações. Caso sejam feitas alterações
          relevantes que ensejem novas autorizações suas, publicaremos uma nova
          política de privacidade.
        </p>
        <p>
          Antes de usar informações para outros fins que não os definidos nesta
          Política de Privacidade, solicitaremos sua autorização.
        </p>

        <h2>9. Lei Aplicável</h2>
        <p>
          Este documento é regido e deve ser interpretado de acordo com as leis
          da República Federativa do Brasil. Fica eleito o Foro da Comarca de
          Pouso Alegre, Minas Gerais como o competente para dirimir quaisquer
          questões porventura oriundas do presente documento, com expressa
          renúncia a qualquer outro, por mais privilegiado que seja.
        </p>
      </Content>
    </>
  )
}
