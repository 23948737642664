import React from "react"
import { Footer } from "../components/Footer"
import { Header } from "../components/Header"
import { PoliticaDePrivacidade } from "../components/PoliticaDePrivacidade"
import { GlobalStyle } from "../styles/global"

export default function politicaDePrivacidade() {
  return (
    <>
      <Header />
      <div className="Content" style={{ flexDirection: "column" }}>
        <PoliticaDePrivacidade />
      </div>
      <Footer />
      <GlobalStyle />
    </>
  )
}
